import { graphql, Link } from 'gatsby';
import { arrayOf, shape, string } from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';

import { pictureFluidType } from '../../../../types/picture';
import { Content } from '../../../common/content';
import { FluidPicture } from '../../../common/picture';
import { Button } from '../../../common/button';
import { getLinkProps } from '../utils';
import './m.scss';

const bem = new BEMHelper({
  name: 'content-teaser-m',
});

export const TeaserM = ({
  id,
  headline,
  description,
  image: [image],
  reference,
}) => {
  const { t } = useTranslation();
  const color = reference.element?.color || reference.element?.departments[0]?.color;

  return (
    <Content id={id} {...bem()} observe useOwnSpacing>
      <div {...bem('details', { [`color-${color}`]: color })}>
        <h2 {...bem('headline')}>
          <Link {...getLinkProps(reference)}>
            {headline}
          </Link>
        </h2>
        <p {...bem('description')}>{description}</p>
        <Button
          {...bem('link')}
          {...getLinkProps(reference)}
          title={reference.title ? reference.title : t('Read more about "{{ topic }}"', { topic: headline })}
          variant="secondary"
        >
          {reference.text || t('Read more')}
        </Button>
      </div>
      <div {...bem('img-container')}>
        <Link {...getLinkProps(reference)}>
          <FluidPicture
            {...image}
            maxWidth={870}
            widths={[218, 435, 870, 1305, 1740]}
            role="presentation"
            {...bem('image')}
          />
        </Link>
      </div>
    </Content>
  );
};

TeaserM.propTypes = {
  id: string.isRequired,
  headline: string.isRequired,
  description: string.isRequired,
  image: arrayOf(pictureFluidType).isRequired,
  reference: shape({
    url: string,
    text: string,
    target: string,
    title: string,
    element: shape({
      slug: string.isRequired,
      departments: arrayOf(shape({
        slug: string.isRequired,
        color: string.isRequired,
      })),
    }),
  }).isRequired,
};

export const fragment = graphql`
  fragment TeaserMContentFragment on CraftGQL_pageContents_pageTeaserM_BlockType {
    id
    headline
    description
    image {
      ...Picture
      fluid_0: url(width: 218, mode: "crop", position: "") # 0.25
      fluid_1: url(width: 435, mode: "crop", position: "") # 0.5
      fluid_2: url(width: 870, mode: "crop", position: "") # max width
      fluid_3: url(width: 1305, mode: "crop", position: "") # 1.5
      fluid_4: url(width: 1740, mode: "crop", position: "") # 2
    }
    reference {
      url
      text
      target
      title
      element {
        ...on CraftGQL_pages_pages_Entry {
          __typename
          slug: pageSlug
          departments: pageDepartments {
            slug
            ...on CraftGQL_departments_department_Entry {
              color: departmentColor
            }
          }
        }

        ...on CraftGQL_departments_department_Entry {
          __typename
          slug,
          color: departmentColor
        }

        ...on CraftGQL_products_product_Entry {
          __typename
          slug
          departments: productDepartment {
            ...on CraftGQL_departments_department_Entry {
              slug
              color: departmentColor
            }
          }

          variant: productVariants(limit: 1) {
            ...on CraftGQL_variants_variants_Entry {
              slug
            }
          }
        }
      }
    }
  }
`;
